export function Tel({ tel }: { tel: string }) {
	return (
		<div className="flex justify-end items-center">
			<a
				href={`tel:+48${tel.replaceAll(" ", "")}`}
				className="flex justify-center items-center leading-none gap-1 whitespace-nowrap"
			>
				<img
					src="/theme/assets/phone.png"
					alt="phone"
					className="object-cover w-4 h-4"
				/>
				<span className="text-xs">+48</span>
				<b className="text-sm">{tel}</b>
			</a>
		</div>
	);
}
