import ReactMarkdown from "react-markdown";
import { useStrapiFetch } from "../../hooks/use-strapi-fetch";
import { getStrapiImageUrl } from "../../lib/get-strapi-image-url";
import { EffectivePageType } from "../../types/strapi-types";
import { NavLink } from "react-router-dom";

export function EffectivePage() {
	const { data } = useStrapiFetch<EffectivePageType>(
		"/api/effective-page?populate=*"
	);

	if (!data) {
		return null;
	}

	const { bg, title, text } = data;

	return (
		<>
			<div className="fixed w-dvw h-dvh z-0">
				<img
					src={getStrapiImageUrl(bg.url)}
					alt={bg.alternativeText}
					className="absolute top-0 left-0 w-full h-full object-contain"
				/>
			</div>

			<div className="relative z-10 md:py-20 py-10 md:space-y-16 space-y-10 px-4">
				<div className="space-y-4">
					<ReactMarkdown className="md:text-2xl text-xl w-full max-w-lg mx-auto text-center font-bold [&_strong]:text-[#dc1f26]">
						{title}
					</ReactMarkdown>

					<ReactMarkdown className="md:text-lg text-base w-full max-w-lg mx-auto text-left [&_li]:list-disc [&_li]:list-inside [&_li]:pl-4 marker:text-[#dc1f26]">
						{text}
					</ReactMarkdown>
				</div>

				<div className="w-full max-w-60 mx-auto">
					<NavLink
						to="/kontakt"
						className="flex items-center bg-[#212020] text-white py-2 px-6 rounded-full text-center text-sm duration-200 hover:bg-[#dc1f26] hover:shadow-md hover:shadow-[#ec797d]"
					>
						<span>Poznaj wszystkie korzyści</span>
						<div className="ml-2 w-2 h-2 border-current border-b-2 border-r-2 rotate-45 -translate-y-1/4" />
					</NavLink>
				</div>
			</div>
		</>
	);
}
