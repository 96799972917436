import ReactMarkdown from "react-markdown";
import { useStrapiFetch } from "../../hooks/use-strapi-fetch";
import { getStrapiImageUrl } from "../../lib/get-strapi-image-url";
import { OfferPageType, OfferPageItemType } from "../../types/strapi-types";

export function OfferPage() {
	const { data } = useStrapiFetch<OfferPageType>("/api/offer-page?populate=*");
	const { data: itemsData } = useStrapiFetch<OfferPageItemType[]>(
		"/api/offer-page-items?populate=*"
	);

	if (!data || !itemsData) {
		return null;
	}

	const { bg, title, text } = data;

	return (
		<>
			<div className="fixed w-dvw h-dvh z-0">
				<img
					src={getStrapiImageUrl(bg.url)}
					alt={bg.alternativeText}
					className="absolute top-0 left-0 w-full h-full object-contain"
				/>
			</div>

			<div className="relative z-10 md:py-20 py-10 md:space-y-16 space-y-10 px-4">
				<div className="space-y-4">
					<ReactMarkdown className="md:text-2xl text-xl w-full max-w-lg mx-auto text-center font-bold [&_strong]:text-[#dc1f26]">
						{title}
					</ReactMarkdown>

					<ReactMarkdown className="md:text-lg text-base w-full max-w-2xl mx-auto text-center">
						{text}
					</ReactMarkdown>
				</div>

				<div className="flex justify-center items-start flex-wrap gap-4 container mx-auto">
					{itemsData.map((item, index) => (
						<div
							key={`item-${index}`}
							className="flex items-start justify-center gap-4"
						>
							<div className="text-4xl font-thin text-[#dc1f26]">+</div>

							<div className="w-full md:max-w-64 max-w-sm text-sm">
								<span className="font-bold">{item.title}</span>
								<ReactMarkdown className="[&_li]:list-disc [&_li]:list-inside [&_li]:pl-4 marker:text-[#dc1f26]">
									{item.text}
								</ReactMarkdown>
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
}
