import { Error } from "./components/error/error";
import { useAppErrorStore } from "./hooks/use-app-error-store";
import { Route, Routes } from "react-router-dom";
import { useAppLoadingStore } from "./hooks/use-app-loading-store";
import { NotFoundPage } from "./pages/not-found-page/not-found-page";
import { MainPage } from "./pages/main-page/main-page";
import { Loader } from "./components/loader/loader";
import { Header } from "./components/header/header";
import { OfferPage } from "./pages/offer-page/offer-page";
import { EffectivePage } from "./pages/effective-page/effective-page";
import { ContactPage } from "./pages/contact-page/contact-page";

export default function App() {
	const { isError } = useAppErrorStore();
	const { tags } = useAppLoadingStore();

	const isLoading = tags.length > 0;

	return (
		<div
			className={`font-sans overflow-hidden min-h-dvh flex flex-col ${
				isError || isLoading ? "h-screen" : ""
			}`}
		>
			{isError && <Error />}

			{isLoading && <Loader />}

			<Header />

			<main className="flex-1">
				<Routes>
					<Route path="" element={<MainPage />} />
					<Route path="/oferta" element={<OfferPage />} />
					<Route path="/efektywne-zarzadzanie" element={<EffectivePage />} />
					<Route path="/kontakt" element={<ContactPage />} />
					<Route path="/*" element={<NotFoundPage />} />
				</Routes>
			</main>
		</div>
	);
}
