import { Navigation } from "./navigation/navigation";
import { Logo } from "./logo";
import { useStrapiFetch } from "../../hooks/use-strapi-fetch";
import { Tel } from "./tel";
import type { HeaderType } from "../../types/strapi-types";

export function Header() {
	const { data } = useStrapiFetch<HeaderType>("/api/header?populate=*");

	if (!data) {
		return null;
	}

	const { tel, logo } = data;

	return (
		<div className="relative w-full bg-[#212020] text-white z-50">
			<header className="relative w-full container mx-auto px-4 grid grid-cols-[1fr_auto_1fr] gap-6 h-12">
				<Logo {...logo} />

				<Navigation tel={tel} />

				<Tel tel={tel} />
			</header>
		</div>
	);
}
