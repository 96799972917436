import ReactMarkdown from "react-markdown";
import { useStrapiFetch } from "../../hooks/use-strapi-fetch";
import { getStrapiImageUrl } from "../../lib/get-strapi-image-url";
import { MainPageType } from "../../types/strapi-types";
import { NavLink } from "react-router-dom";

const buttonClassName =
	"inline-block flex-1 bg-[#212020] text-white py-2 px-6 rounded-full text-center text-sm duration-200 hover:bg-[#dc1f26] hover:shadow-md hover:shadow-[#ec797d]";

export function MainPage() {
	const { data } = useStrapiFetch<MainPageType>("/api/main-page?populate=*");

	if (!data) {
		return null;
	}

	const { bg, logo, text } = data;

	return (
		<>
			<div className="fixed w-dvw h-dvh z-0">
				<img
					src={getStrapiImageUrl(bg.url)}
					alt={bg.alternativeText}
					className="absolute top-0 left-0 w-full h-full object-contain"
				/>
			</div>

			<div className="relative z-10 md:py-20 py-10 md:space-y-16 space-y-10 px-4">
				<img
					src={getStrapiImageUrl(logo.url)}
					alt={logo.alternativeText}
					className="mx-auto w-full max-w-sm object-contain"
				/>

				<ReactMarkdown className="md:text-2xl text-xl w-full max-w-lg mx-auto text-center leading-relaxed">
					{text}
				</ReactMarkdown>

				<div className="w-full max-w-md mx-auto flex gap-y-4 gap-x-6 justify-between items-center flex-wrap">
					<NavLink to="/oferta" className={buttonClassName}>
						Oferta
					</NavLink>
					<NavLink to="/kontakt" className={buttonClassName}>
						Kontakt
					</NavLink>
				</div>
			</div>
		</>
	);
}
