import axios from "axios";
import { useState, useEffect } from "react";
import { useAppErrorStore } from "./use-app-error-store";
import { useAppLoadingStore } from "./use-app-loading-store";
import { getStrapiUri } from "../lib/get-strapi-uri";

export function useStrapiFetch<T>(uri: string) {
	const [data, setData] = useState<T>();
	const { setError } = useAppErrorStore();
	const { setLoading, unsetLoading } = useAppLoadingStore();

	useEffect(() => {
		async function fetchBars() {
			setLoading(uri);
			try {
				const strapiUri = getStrapiUri(uri);
				const res = await axios.get(strapiUri);
				const data = res.data.data as T;
				setData(data);
			} catch (e: unknown) {
				setError();
			}
			unsetLoading(uri);
		}
		fetchBars();
	}, [setError, uri, setLoading, unsetLoading]);

	return { data };
}
