import ReactMarkdown from "react-markdown";
import { useStrapiFetch } from "../../hooks/use-strapi-fetch";
import { ContactPageType } from "../../types/strapi-types";
import { ContactForm } from "../../components/contact-form/contact-form";

export function ContactPage() {
	const { data } = useStrapiFetch<ContactPageType>(
		"/api/contact-page?populate=*"
	);

	if (!data) {
		return null;
	}

	const { title, text } = data;

	return (
		<div className="relative z-10 md:py-20 py-10 md:space-y-16 space-y-10 px-4 min-h-[calc(100dvh-3rem)] bg-[#212020] text-white">
			<div className="space-y-4">
				<ReactMarkdown className="md:text-2xl text-xl w-full max-w-lg mx-auto text-center font-bold [&_strong]:text-[#dc1f26]">
					{title}
				</ReactMarkdown>

				<ReactMarkdown className="md:text-lg text-base w-full container mx-auto text-center [&_a]:underline">
					{text}
				</ReactMarkdown>
			</div>

			<div className="w-full max-w-xl mx-auto">
				<ContactForm />
			</div>
		</div>
	);
}
