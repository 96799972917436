import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, FormProvider, useForm } from "react-hook-form";
import axios from "axios";
import { getStrapiUri } from "../../lib/get-strapi-uri";
import { useState } from "react";

const formSchema = z.object({
	email: z.string().trim().email("Niepoprawny email"),
	tel: z.string().trim().min(9, "Niepoprawny numer telefonu"),
	text: z
		.string()
		.trim()
		.min(10, "Wiadomosc jest za krotka")
		.max(5000, "Wiadomosc jest za długa"),
	rodo: z.boolean().refine((value) => value, {
		message: "Zgoda jest wymagana",
	}),
	newsletter: z.boolean(),
});

export function ContactForm() {
	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			email: "",
			tel: "",
			text: "",
			rodo: false,
			newsletter: false,
		},
	});

	const isSubmitting = form.formState.isSubmitting;

	const { errors } = form.formState;

	const [submitError, setSubmitError] = useState("");
	const [onSuccess, setOnSuccess] = useState("");

	async function onSubmit(values: z.infer<typeof formSchema>) {
		try {
			setSubmitError("");
			setOnSuccess("");

			const formData = new FormData();
			formData.append("data", JSON.stringify({ ...values }));

			await axios.post(getStrapiUri("/api/contact-requests"), { data: values });

			setOnSuccess("Wiadomość została wysłana");

			form.reset();
		} catch (error) {
			setSubmitError("Wystąpił błąd podczas wysyłania wiadomości");
		}
	}

	return (
		<FormProvider {...form}>
			<form
				onSubmit={form.handleSubmit(onSubmit)}
				className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4"
			>
				<Controller
					control={form.control}
					name="tel"
					render={({ field }) => (
						<div>
							<input
								{...field}
								type="tel"
								placeholder="TELEFON"
								autoComplete="tel"
								disabled={isSubmitting}
								className="border-2 border-[var(--main-color)] p-2 w-full rounded-lg text-black"
							/>
							{errors.tel && (
								<p className="text-red-600 text-sm">{errors.tel.message}</p>
							)}
						</div>
					)}
				/>

				<Controller
					control={form.control}
					name="email"
					render={({ field }) => (
						<div>
							<input
								{...field}
								type="email"
								placeholder="EMAIL"
								autoComplete="email"
								disabled={isSubmitting}
								className="border-2 border-[var(--main-color)] p-2 w-full rounded-lg text-black"
							/>
							{errors.email && (
								<p className="text-red-600 text-sm">{errors.email.message}</p>
							)}
						</div>
					)}
				/>

				<Controller
					control={form.control}
					name="text"
					render={({ field }) => (
						<div className="lg:col-span-2">
							<textarea
								{...field}
								rows={8}
								placeholder="TREŚĆ WIADOMOŚCI"
								disabled={isSubmitting}
								className="border-2 border-[var(--main-color)] p-2 w-full rounded-lg text-black"
							/>
							{errors.text && (
								<p className="text-red-600 text-sm">{errors.text.message}</p>
							)}
						</div>
					)}
				/>

				<Controller
					control={form.control}
					name="rodo"
					render={({ field }) => (
						<div className="lg:col-span-2">
							<div className="flex gap-4 items-start">
								<input
									{...field}
									id="form-rodo"
									type="checkbox"
									disabled={isSubmitting}
									value={field.value ? "true" : "false"}
									className="sr-only"
								/>
								<div className="relative flex-none w-5 h-5 bg-black rounded-full">
									<div
										className={`absolute top-1/2 left-1/2 -translate-x-1/2 w-2 h-4 border-[#dc1f26] border-b-2 border-r-2 rotate-45 -translate-y-3/4 duration-150 opacity-0 ${
											field.value ? "opacity-100" : ""
										}`}
									/>
								</div>
								<label htmlFor="form-rodo" className="text-xs cursor-pointer">
									<p>Zgoda na przetwarzanie danych z formularza kontaktowego</p>
									<p className="opacity-50">
										Wyrażam zgodę na przetwarzanie moich danych osobowych
										podanych w niniejszym formularzu w celu uzyskania odpowiedzi
										na złożone zapytanie. Moja zgoda na przetwarzanie danych
										osobowych jest dobrowolna lecz niezbędna do uzyskania
										odpowiedzi.
									</p>
								</label>
							</div>
							{errors.rodo && (
								<p className="text-red-600 text-sm">{errors.rodo.message}</p>
							)}
						</div>
					)}
				/>

				<Controller
					control={form.control}
					name="newsletter"
					render={({ field }) => (
						<div className="lg:col-span-2">
							<div className="flex gap-4 items-start">
								<input
									{...field}
									id="form-newsletter"
									type="checkbox"
									disabled={isSubmitting}
									value={field.value ? "true" : "false"}
									className="sr-only"
								/>
								<div className="relative flex-none w-5 h-5 bg-black rounded-full">
									<div
										className={`absolute top-1/2 left-1/2 -translate-x-1/2 w-2 h-4 border-[#dc1f26] border-b-2 border-r-2 rotate-45 -translate-y-3/4 duration-150 opacity-0 ${
											field.value ? "opacity-100" : ""
										}`}
									/>
								</div>
								<label
									htmlFor="form-newsletter"
									className="text-xs cursor-pointer"
								>
									<p>
										Zgoda na otrzymywanie informacji handlowych droga
										elektroniczną
									</p>
									<p className="opacity-50">
										Wyrażam zgodę na otrzymywanie od Project 4 Data Sp. z o.o.
										drogą elektroniczną na wskazany przeze mnie w Formularzu
										adres e-mail informacji handlowych, dotyczących produktów i
										uslug oferowanych przez Project 4 Data Sp. z o.o. w
										rozumieniu ustawy o świadczeniu usług drogą elektroniczną z
										dnia 18 lipca 2002 r. (Dz. U z 2013 r., poz. 1422 ze zm.)
									</p>
								</label>
							</div>
							{errors.newsletter && (
								<p className="text-red-600 text-sm">
									{errors.newsletter.message}
								</p>
							)}
						</div>
					)}
				/>

				{!!submitError && (
					<div className="lg:col-span-2 text-center text-lg text-red-600">
						<p>{submitError}</p>
					</div>
				)}

				{!!onSuccess && (
					<div className="lg:col-span-2 text-center text-lg">
						<p>{onSuccess}</p>
					</div>
				)}

				<div className="lg:col-span-2 flex justify-center">
					<button
						type="submit"
						disabled={isSubmitting}
						className="flex items-center bg-white text-black hover:text-white py-2 px-6 rounded-full text-center text-sm duration-200 hover:bg-[#dc1f26] hover:shadow-md hover:shadow-[#ec797d]"
					>
						{isSubmitting ? "Wysyłanie..." : "Wyślij wiadomość"}
					</button>
				</div>
			</form>
		</FormProvider>
	);
}
