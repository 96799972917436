import { create } from "zustand";

interface AppLoadingStore {
	tags: string[];
	setLoading: (tag: string) => void;
	unsetLoading: (tag: string) => void;
}

export const useAppLoadingStore = create<AppLoadingStore>((set) => ({
	tags: [],
	setLoading: (tag) => set((state) => ({ tags: [...state.tags, tag] })),
	unsetLoading: (tag) =>
		set((state) => ({ tags: state.tags.filter((t) => t !== tag) })),
}));
