import { useMediaQuery } from "../../../hooks/use-media-query";
import { NavigationLinks } from "./navigation-links";
import { NavigationMenu } from "./navigation-menu";

export function Navigation({ tel }: { tel: string }) {
	const { isMobile } = useMediaQuery();

	return (
		<>
			{isMobile ? (
				<NavigationMenu />
			) : (
				<nav className="text-sm flex items-center h-full">
					<NavigationLinks />
				</nav>
			)}
		</>
	);
}
